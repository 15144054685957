<template>
  <div class="mention-box" :class="dynamicClasses">
    <button
      v-if="!isOpen"
      class="mention-box__placeholder"
      @click="openMentionbox"
    >
      {{ placeholder }}
    </button>
    <div v-else>
      <content-editable-input
        ref="contentEditableInput"
        v-model="mentionContent"
        class="mention-box__input"
        @focus="handleInputFocus"
      />
      <member-search-list
        v-show="users.length > 0"
        class="mention-box__result-list"
        :class="{ 'mention-box__result-list--above': openMentionsAbove }"
        :members="users"
        @memberSelected="addMention"
      />
    </div>
    <uploaded-image-preview
      v-if="hasImage"
      :images="images"
      @delete="handleDeleteImage"
    />
    <div v-if="isOpen" class="mention-box__actions">
      <button
        class="mention-box__button button button--secondary"
        type="button"
        @mousedown="injectNewMention"
      >
        <icon-component name="at" class="mention-box__button-icon button__icon" />
        Mention someone
      </button>
      <image-upload-button
        @click="handleImageUploadClick"
      />
    </div>
  </div>
</template>

<script>
import imageUploadMixin from '@/mixins/image-upload-mixin';
import ContentEditableInput from '@/components/Mention/ContentEditableInput.vue';
import ImageUploadButton from '@/components/ImageUpload/ImageUploadButton.vue';
import UploadedImagePreview from '@/components/ImageUpload/UploadedImagePreview.vue';
import MemberSearchList from '@/components/Search/MemberSearchList.vue';
import { USER_SEARCH_QUERY } from '@/graphql/queries/user-queries';
import { makeCancellable } from '@/utils/make-cancellable';
import { mapGetters } from 'vuex';

export default {
  name: 'MentionBox',
  components: {
    ImageUploadButton,
    UploadedImagePreview,
    ContentEditableInput,
    MemberSearchList,
  },
  mixins: [imageUploadMixin],
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Write a comment...',
    },
    /**
     * The mentionable is the post, thread, or other root object that the MentionBox
     * is being rendered for. At a minimum it must include the mentionable's id.
     * This is used to return relevant users in the userSuggestions.
     */
    mentionable: {
      type: Object,
      required: true,
    },
    mentionableType: {
      type: String,
      required: true,
    },
    requiresAuth: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
      mentionLimit: 3,
      mentionContent: '',
      transformedMentionContent: '',
      // eslint-disable-next-line no-useless-escape
      mentionMatch: new RegExp(/\B@{1}([\w,-\/.()]*)/),
      // Mention Picker state
      term: '',
      // Array of users for mention picker
      users: [],
      // State based on screen edge detection
      openMentionsAbove: false,
      // Cancellable wrapped Promise from make-cancellable when a user search is in flight
      cancellableUserQuery: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
    ]),
    dynamicClasses() {
      const classes = [];
      if (this.isOpen) {
        classes.push('mention-box--open');
      } if (this.hasImage) {
        classes.push('mention-box--hasImage');
      }
      return classes.join(' ');
    },
    mentionCount() {
      const pattern = /<inline-mention/;
      const mentions = this.transformedMentionContent.match(new RegExp(pattern, 'g'));
      return mentions ? mentions.length : 0;
    },
    // Returns true if the mention trigger is proceeded by a space
    prependSpace() {
      if (this.mentionMatch.test(this.mentionContent)) {
        return /^\s/.test(this.mentionContent.match(this.mentionMatch)[0]);
      }
      // Mention is being injected from parent, not via member-search
      return false;
    },
    userQuery: {
      get() {
        return this.cancellableUserQuery;
      },
      set(val) {
        // If the cancellableUserQuery is not undefined, cancel the promise
        if (this.cancellableUserQuery) {
          this.cancellableUserQuery.cancel();
        }
        this.cancellableUserQuery = val;
      },
    },
  },
  watch: {
    mentionContent(to) {
      this.updateContent();

      if (this.mentionMatch.test(to)) {
        const match = to.match(this.mentionMatch);
        this.setOpenMentionsAbove();
        this.handleMentionSearch(match[1]);
      } else {
        this.clearMentioning();
      }
    },
  },
  created() {
    if (this.value.length > 0) { this.isOpen = true; }
  },
  mounted() {
    if (this.isOpen) {
      this.mentionContent = this.value;
    }
  },
  methods: {
    clear() {
      this.mentionContent = '';
      this.clearImages();
    },
    injectNewMention(event) {
      if (!this.userIsLoggedIn) {
        this.$store.dispatch('openRegisterDialog');
        return;
      }
      // Wait for call stack to clear before injecting mention so that focus is
      // set back to the contentEditableInput correctly in the watcher.
      window.setTimeout(() => {
        this.mentionContent = `${this.mentionContent} @`;
        this.$snowplow.trackButtonEvent({
          data: {
            type: '@mentioning',
            text: event.target.textContent.trim(),
            classes: event.target.classList,
            id: event.target.id,
          },
        });
      }, 0);
    },
    openMentionbox() {
      this.isOpen = true;
      this.$nextTick(() => {
        this.$refs.contentEditableInput.focus();
      });
    },
    updateContent() {
      this.transformedMentionContent = this.transformContent(this.mentionContent || '');
      // Always emit the transformedMentionContent as this is what should be sent to the API
      this.$emit('input', this.transformedMentionContent);
    },
    transformContent(content) {
      // Mentionbox must be open for removeExtraDivs, we might want to switch the v-if/else to v-show?
      if (!this.isOpen) {
        this.openMentionbox();
      }
      let tmp = this.mentionsToComponents(content);
      tmp = this.appendImages(tmp);
      tmp = this.$refs.contentEditableInput.removeExtraDivs(tmp);
      return tmp;
    },
    mentionsToComponents(content) {
      const pattern = new RegExp(/<span class="mention-box__mention" data-user-id="([a-zA-z0-9]*)" contenteditable="false">([\w,._@\-\s]*)<\/span>/, 'g');
      return content.replace(pattern, '<inline-mention username="$2" user-id="$1"/>');
    },
    setOpenMentionsAbove() {
      const { bottom } = this.$el.getBoundingClientRect();
      const mentionBoxResultsHeight = 160;
      this.openMentionsAbove = bottom + mentionBoxResultsHeight > window.innerHeight;
    },
    handleMentionSearch(value) {
      this.term = value;
      if (!this.term) {
        this.users = [];
      }
      this.getUserSuggestions();
    },
    getUserSuggestions() {
      if (this.mentionCount >= this.mentionLimit) {
        this.users = [];
        this.$refs.contentEditableInput.removeLastCharacter();
        this.$store.dispatch('addToastNotification', {
          toastType: 'error',
          description: 'You may only mention three people per comment.',
        });
        return;
      }

      this.userQuery = makeCancellable(this.$apollo.query({
        query: USER_SEARCH_QUERY,
        variables: {
          text: this.term,
          type: this.mentionableType,
          id: this.mentionable.id,
        },
      }));

      this.userQuery
        .then((response) => {
          this.users = response.data.userSearch;
          this.userQuery = undefined;
        })
        .catch(() => {}); // Catch cancelled or rejected promise
    },
    injectMention(user) {
      // Ensure mentionbox is open before attempting to inject mention
      this.openMentionbox();
      this.$nextTick(() => {
        this.addMention(user, true);
        this.$refs.contentEditableInput.placeCursorAtEnd();
      });
    },
    addMention(user, injecting = false) {
      this.clearMentioning();
      if (injecting) {
        this.mentionContent = this.mentionContent + this.mentionMarkup(user, this.prependSpace);
      } else {
        this.mentionContent = this.mentionContent.replace(this.mentionMatch, this.mentionMarkup(user, this.prependSpace));
      }
    },
    clearMentioning() {
      if (this.userQuery) {
        this.userQuery = undefined;
      }
      this.users = [];
    },
    mentionMarkup(user, prependSpace) {
      const mentionMarkup = `<span class="mention-box__mention" data-user-id="${user.id}" contenteditable="false">${user.username}</span>`;

      if (prependSpace) {
        return `&nbsp;${mentionMarkup}&nbsp;`;
      }
      // Note that there must be a space after injected mention markup to ensure
      // cursor placement is correct in safari and firefox. Without this space
      // the cursor will appear on the left side of the contenteditable input
      return `${mentionMarkup}&nbsp;`;
    },
    handleDeleteImage() {
      this.updateContent();
      this.$refs.contentEditableInput.focus();
    },
    handleInputFocus() {
      if (
        !this.$store.getters.userIsLoggedIn
        && this.requiresAuth
      ) {
        const messaging = {
          POST: 'Create an account to comment.',
          THREAD: 'Create an account to reply to this forum.',
          USER_PROFILE_STATUS_REPLY: 'Create an account to reply to this status.',
        }[this.mentionableType] || '';

        this.$store.dispatch('openRegisterDialog', { dialogText: messaging, isUGC: true });
        this.$el.blur();
      }
      if (
        this.$store.getters.userIsLoggedIn
        && this.$store.getters.userIsUnverified
      ) {
        this.$store.dispatch('openVerificationPrompt', { dialogHeading: 'Verify your account' });
        this.$el.blur();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_mention-box';
</style>

<style lang="scss"> // no scoped
  /**
   * 1. Sometimes the browser will append a deprecated font tag in the
   *    contenteditable when you delete a mention. This is to ensure that styling
   *    from the mention doesn't come over to regular text.
   * 2. must be outside of scoped style tag to reach element added programatically
   */

  .mention-box__input font { /* [1] */
    color: $text-color;
  }

    .mention-box__mention { /* [2] */
      color: $cyan;
    }
</style>

<docs>

The MentionBox can be pre-populated with a value. This is useful for implementing
features that allow editing, or binding value to a form's model.

</docs>
